<template>
  <div id="user-list">
    <!-- app drawer -->
    <brand-aside
      v-if="asideActive && formReady && aside === 'brand'"
      v-model="asideActive"
      :resource="resource"
      @changed="loadBrands(); asideActive = false"
    ></brand-aside>

    <brand-media-aside
      v-if="asideActive && formReady && aside === 'brand_media'"
      v-model="asideActive"
      :resource="resource"
      @changed="loadBrands(); asideActive = false"
    ></brand-media-aside>

    <index-filter :cols="[3, 3, 6]">
      <search-filter
        slot="col_0"
        v-model="filters"
      />
      <distributor-id-filter
        slot="col_1"
        v-model="filters"
      />
      <div
        slot="col_2"
        class="d-flex justify-end flex-wrap"
      >
        <template
          v-if="selectedRows.length"
        >
          <v-btn
            color="success"
            class="mb-4 me-3"
            :loading="loading"
            @click.stop="updateAllBrands({ active: 1 })"
          >
            <span>Activate</span>
          </v-btn>
          <v-btn
            color="warning"
            class="mb-4 me-3"
            :loading="loading"
            @click.stop="updateAllBrands({ active: 0 })"
          >
            <span>Deactive</span>
          </v-btn>
        </template>
        <v-btn
          color="primary"
          class="mb-4 me-3"
          :loading="loading"
          @click.stop="addNewResoure"
        >
          <v-icon>{{ icons.mdiPlus }}</v-icon>
          <span>Add New Brand</span>
        </v-btn>
      </div>
    </index-filter>

    <v-card>
      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="brandListTable"
        :options.sync="options"
        :server-items-length="totalListTable"
        :loading="loading"
        show-select
      >
        <!-- ID -->
        <template #[`item.id`]="{item}">
          <span
            class="cursor-pointer"
            @click="setBrand(item, 'brand')"
          >{{ item.id }}</span>
        </template>

        <!-- MEDIA -->
        <template #[`item.media`]="{item}">
          <span
            class="cursor-pointer"
            @click="setBrand(item, 'brand_media')"
          >
            <v-img
              :src="item.manifest.logo_path"
              max-width="50"
            >
            </v-img>
          </span>
        </template>

        <!-- Key -->
        <template #[`item.slug`]="{item}">
          <span
            class="cursor-pointer"
            @click="setBrand(item, 'brand')"
          >
            {{ item.slug }}
          </span>
        </template>

        <!-- name -->
        <template #[`item.brand`]="{item}">
          <div
            class="cursor-pointer"
            @click="setBrand(item, 'brand')"
          >
            {{ t(item.name) }}
          </div>
          <small v-if="item.distributor">
            DIST: {{ item.distributor.nicename || item.distributor.slug }}
          </small>
        </template>

        <!-- Active -->
        <template #[`item.status`]="{item}">
          <v-chip :color="item.active ? 'success' : ''">
            {{ item.active ? 'Active' : 'Inactive' }}
          </v-chip>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                link
                @click="setBrand(item, 'brand')"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPencil }}
                  </v-icon>
                  <span>Update</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                link
                @click="setBrand(item, 'brand_media')"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                  </v-icon>
                  <span>Update Logo</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                link
                @click="deleteBrand(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { destroyBrand } from '@api/product'
import {
mdiAccountOutline,
mdiDeleteOutline,
mdiDotsVertical,
mdiExportVariant,
mdiFileDocumentOutline,
mdiPencil,
mdiPlus,
mdiSquareEditOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api'

// sidebar
import { avatarText } from '@core/utils/filter'

import { DistributorIdFilter, IndexFilter, SearchFilter } from '@/components'
import { useNotifyErrors } from '@/composables'
import { t } from '@/plugins/i18n'
import BrandAside from '../product-resource/BrandAside.vue'
import BrandMediaAside from '../product-resource/BrandMediaAside.vue'

import useBrandList from './useBrandList'

export default {
  components: {
    BrandAside,
    BrandMediaAside,
    IndexFilter,
    SearchFilter,
    DistributorIdFilter,
  },
  setup() {
    const {
      brandListTable,
      tableColumns,
      filters,
      totalListTable,
      loading,
      options,
      totalLocal,
      selectedRows,

      loadBrands,
      updateAllBrands,
    } = useBrandList()

    const asideActive = ref(false)
    const formReady = ref(true)
    const resource = ref(false)
    const aside = ref('')

    const rootBrandName = brands => {
      const brand = brands.find(c => !c.parent_id)

      return brand ? t(brand.name) : ''
    }

    // const brandegoriesOptions = commonStore.state.brandegories.map(brand => ({ title: field(brand.name), value: brand.id }))

    const deleteBrand = brand => destroyBrand(brand.id).then(loadBrands).catch(useNotifyErrors)

    const setBrand = (brand, type) => {
      resource.value = brand
      asideActive.value = true
      formReady.value = false
      aside.value = type
      setTimeout(() => {
        formReady.value = true
      })
    }

    const addNewResoure = () => {
      resource.value = null
      formReady.value = false
      aside.value = 'brand'
      asideActive.value = true
      setTimeout(() => {
        formReady.value = true
      })
    }

    return {
      brandListTable,
      tableColumns,
      totalListTable,
      filters,

      // categoriesOptions,
      loading,
      options,
      totalLocal,
      asideActive,
      formReady,
      resource,
      selectedRows,
      avatarText,
      loadBrands,
      deleteBrand,
      aside,
      setBrand,
      addNewResoure,
      updateAllBrands,
      rootBrandName,

      t,

      // icons
      icons: {
        mdiPencil,
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
